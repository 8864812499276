var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-card", {
    staticStyle: {
      "overflow-x": "scroll"
    },
    attrs: {
      bordered: false
    }
  }, [_c("h3", [_vm._v("物流信息")]), _c("a-tabs", {
    attrs: {
      "default-active-key": "1"
    },
    on: {
      change: _vm.callback
    }
  }, [_c("a-tab-pane", {
    key: "1",
    attrs: {
      tab: "商家寄往平台"
    }
  }, [_vm.express ? _c("div", [_c("div", {
    staticClass: "express"
  }, [_c("div", {
    staticClass: "express_info"
  }, [_vm._v(" 快递公司 "), _c("span", {
    staticClass: "info"
  }, [_vm._v(_vm._s(_vm.express))])]), _c("div", {
    staticClass: "express_info"
  }, [_vm._v(" 运单编号 "), _c("span", {
    staticClass: "info"
  }, [_vm._v(_vm._s(_vm.expressno))]), _c("span", {
    ref: "copy",
    staticClass: "copy",
    on: {
      click: function click($event) {
        return _vm.copy(_vm.expressno);
      }
    }
  }, [_c("img", {
    attrs: {
      src: require("./../../assets/img/copy.png"),
      alt: ""
    }
  })])])]), !_vm.ShowHidden ? _c("div", {
    staticClass: "logistic"
  }, _vm._l(_vm.logList, function (item, index) {
    return _c("a-timeline-item", {
      key: index
    }, [_c("span", {
      staticClass: "logTime"
    }, [_vm._v(_vm._s(item.a))]), _c("span", {
      staticClass: "logInfo addressInfo"
    }, [_vm._v(_vm._s(item.z))]), _c("span", {
      staticClass: "logInfo"
    }, [_vm._v(_vm._s(item.c))])]);
  }), 1) : _vm._e(), _vm.logList.length > 0 ? _c("div", {
    staticClass: "showInfo",
    on: {
      click: function click($event) {
        _vm.ShowHidden = !_vm.ShowHidden;
      }
    }
  }, [!_vm.ShowHidden ? _c("div", [_vm._v(" 收起信息 "), _c("a-icon", {
    attrs: {
      type: "up"
    }
  })], 1) : _vm._e(), _vm.ShowHidden ? _c("div", [_vm._v(" 展开信息 "), _c("a-icon", {
    attrs: {
      type: "down"
    }
  })], 1) : _vm._e()]) : _vm._e()]) : _c("a-empty")], 1), _vm.isseller == 1 ? _c("a-tab-pane", {
    key: "2",
    attrs: {
      tab: "平台寄往买家",
      "force-render": ""
    }
  }, [_vm.express2 ? _c("div", [_c("div", {
    staticClass: "express"
  }, [_c("div", {
    staticClass: "express_info"
  }, [_vm._v(" 快递公司 "), _c("span", {
    staticClass: "info"
  }, [_vm._v(_vm._s(_vm.express2))])]), _c("div", {
    staticClass: "express_info"
  }, [_vm._v(" 运单编号 "), _c("span", {
    staticClass: "info"
  }, [_vm._v(_vm._s(_vm.expressno2))]), _c("span", {
    ref: "copy",
    staticClass: "copy",
    on: {
      click: function click($event) {
        return _vm.copy(_vm.expressno2);
      }
    }
  }, [_c("img", {
    attrs: {
      src: require("./../../assets/img/copy.png"),
      alt: ""
    }
  })])])]), !_vm.ShowHidden ? _c("div", {
    staticClass: "logistic"
  }, _vm._l(_vm.sysList, function (item, index) {
    return _c("a-timeline-item", {
      key: index
    }, [_c("span", {
      staticClass: "logTime"
    }, [_vm._v(_vm._s(item.a))]), _c("span", {
      staticClass: "logInfo addressInfo"
    }, [_vm._v(_vm._s(item.z))]), _c("span", {
      staticClass: "logInfo"
    }, [_vm._v(_vm._s(item.c))])]);
  }), 1) : _vm._e(), _vm.sysList.length > 0 ? _c("div", {
    staticClass: "showInfo",
    on: {
      click: function click($event) {
        _vm.ShowHidden = !_vm.ShowHidden;
      }
    }
  }, [!_vm.ShowHidden ? _c("div", [_vm._v(" 收起信息 "), _c("a-icon", {
    attrs: {
      type: "up"
    }
  })], 1) : _vm._e(), _vm.ShowHidden ? _c("div", [_vm._v(" 展开信息 "), _c("a-icon", {
    attrs: {
      type: "down"
    }
  })], 1) : _vm._e()]) : _vm._e()]) : _c("a-empty")], 1) : _vm._e(), _vm.isseller == 2 ? _c("a-tab-pane", {
    key: "2",
    attrs: {
      tab: "平台寄往卖家",
      "force-render": ""
    }
  }, [_vm.express2 ? _c("div", [_c("div", {
    staticClass: "express"
  }, [_c("div", {
    staticClass: "express_info"
  }, [_vm._v(" 快递公司 "), _c("span", {
    staticClass: "info"
  }, [_vm._v(_vm._s(_vm.express2))])]), _c("div", {
    staticClass: "express_info"
  }, [_vm._v(" 运单编号 "), _c("span", {
    staticClass: "info"
  }, [_vm._v(_vm._s(_vm.expressno2))]), _c("span", {
    ref: "copy",
    staticClass: "copy",
    on: {
      click: function click($event) {
        return _vm.copy(_vm.expressno2);
      }
    }
  }, [_c("img", {
    attrs: {
      src: require("./../../assets/img/copy.png"),
      alt: ""
    }
  })])])]), !_vm.ShowHidden ? _c("div", {
    staticClass: "logistic"
  }, _vm._l(_vm.sysList, function (item, index) {
    return _c("a-timeline-item", {
      key: index
    }, [_c("span", {
      staticClass: "logTime"
    }, [_vm._v(_vm._s(item.a))]), _c("span", {
      staticClass: "logInfo addressInfo"
    }, [_vm._v(_vm._s(item.z))]), _c("span", {
      staticClass: "logInfo"
    }, [_vm._v(_vm._s(item.c))])]);
  }), 1) : _vm._e(), _vm.sysList.length > 0 ? _c("div", {
    staticClass: "showInfo",
    on: {
      click: function click($event) {
        _vm.ShowHidden = !_vm.ShowHidden;
      }
    }
  }, [!_vm.ShowHidden ? _c("div", [_vm._v(" 收起信息 "), _c("a-icon", {
    attrs: {
      type: "up"
    }
  })], 1) : _vm._e(), _vm.ShowHidden ? _c("div", [_vm._v(" 展开信息 "), _c("a-icon", {
    attrs: {
      type: "down"
    }
  })], 1) : _vm._e()]) : _vm._e()]) : _c("a-empty")], 1) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };